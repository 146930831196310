import { parseNumber } from 'utils'

const formatNumber = (
  number: any,
  toFixed?: any,
  minimumFractionDigits?: number,
): string => {
  return new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: Number.isInteger(parseNumber(number))
      ? 0
      : minimumFractionDigits || 0,
  }).format(parseNumber(number, toFixed))
}

/**
 * @deprecated
 * Please use formatNumber from utils/helpers/formatter
 *
 * @param number
 * @returns
 */
const formatCurrency = (string: string | number, toFixed?: any) => {
  if (Number.isFinite(toFixed)) {
    return formatNumber(parseFloat(String(string)).toFixed(toFixed))
  }

  return formatNumber(string)
}

const formatRoundingMethod = (
  amount: any,
  method: string,
  decimalPlace: number,
): number => {
  const fixNum = parseNumber(amount)
  const decimalPlacePow = Math.pow(10, decimalPlace || 0)
  const amountPow = parseNumber(Math.abs(fixNum * decimalPlacePow), 5)

  const negativeSign = fixNum < 0 ? -1 : 1
  const mapMethod = {
    round_up: (Math.ceil(amountPow) * negativeSign) / decimalPlacePow,
    round_down: (Math.floor(amountPow) * negativeSign) / decimalPlacePow,
    rounding: (Math.round(amountPow) * negativeSign) / decimalPlacePow,
  }

  return mapMethod[method || 'rounding']
}

/**
 * @deprecated
 * Please use formatNumber from utils/helpers/formatter
 *
 * @param number
 * @returns
 */
const formatPercentage = (value: string) => value && `${value}%`

const formatDecimalNumber = (value: number) => {
  if (!value) return 0

  if (value % 1 !== 0) {
    return Number(value).toFixed(2)
  }

  return value
}

const formatNumberSafe = ({
  number,
  isPercentage,
  toFixed,
}: {
  number: any
  isPercentage?: boolean
  toFixed?: 2
}): string => {
  if (number === null || number === undefined || !isFinite(number)) {
    return '0'
  }

  const num = Number(number)
  if (isNaN(num)) {
    return '0'
  }

  if (num % 1 === 0) {
    const formatted = new Intl.NumberFormat('ja-JP').format(num)
    return isPercentage ? `${formatted}%` : formatted
  }

  const decimalPlaces = toFixed !== undefined ? Number(toFixed) : 2
  const formatted = num.toFixed(decimalPlaces)

  // if (formatted.includes('.')) {
  //   formatted = formatted.replace(/\.?0+$/, '')
  // }

  const [intPart, decimalPart] = formatted.split('.')
  const formattedIntPart = new Intl.NumberFormat('ja-JP').format(
    Number(intPart),
  )

  const result = decimalPart
    ? `${formattedIntPart}.${decimalPart}`
    : formattedIntPart
  return isPercentage ? `${result}%` : result
}

export {
  formatCurrency,
  formatDecimalNumber,
  formatNumber,
  formatNumberSafe,
  formatPercentage,
  formatRoundingMethod,
}
